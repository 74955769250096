.background-svg::before {
    content: "";
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image:url('../assets/background/diamond-sunset.svg');
    background-repeat: repeat;
    pointer-events: none; 
    opacity: 0.5;
    z-index: -1;
  }
  
  .background-svg {
    position: relative;
  }