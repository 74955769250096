

@keyframes blink {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}

.blinking {
  animation: blink 2s linear infinite;
}

@keyframes alert {
  0% {background-color: white;}
  50% {background-color: rgb(253, 135, 135);}
  100% {background-color: white;}
}

.alert {
  animation-name: alert;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.filter-form{
  width: 100% !important;
}

.filter-flex-box{
  width: 100%;

}

@media (max-width: 1000px){
  .filter-flex-box{
    flex-direction: column;
  }
}
@media (min-width: 1000px){
  .filter-flex-box{
    flex-direction: row;
  }
}