.login-welcome-flex {
    position: absolute;
}

.login-welcome-text {
    color: #66666a;
    font-size: 80px;
    font-weight: lighter;
}

.login-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0);
}

.login-box {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    padding: 50px;
    min-width: 30%;
    transition: all 0.3s ease;
    
    @media (max-width: 1550px) {
        width: 40%;
    }
    @media (max-width: 1200px){
        width: 50%;
    }
}


.login-button-text {
    color: #ffffff;
    font-size: 20px;
    font-weight: lighter;
    vertical-align: middle;
    position: relative;
    padding: 30;
}

.login-modal-header {
    color: #636060;
    font-size: 20px;
    font-weight:normal;
    vertical-align: middle;
    position: relative;
    padding: 30;
    text-align: left;
}

.login-modal-continue{
    color: #ffffff;
    font-size: 20px;
    font-weight: lighter;
    vertical-align: middle;
    position: relative;
    padding: 30;
}
.login-container-inFlex {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 80vh;
    padding-top: 10vh;
    padding-bottom: 10vh;
    justify-content: center;
    align-items: center;
}

.login-heading {
    margin-bottom: 4vh;
    color: rgba(7, 125, 114, 1);
}

.form-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}


.login-error-text {
    align-self: start;
    color: rgb(212, 12, 12);
    font-weight: 800;
    font-size: 15px;
}