.title-brand-login {
    color: #58585B;
    font-size: 30px;
    vertical-align: middle;
    text-align: center;
    position: relative;
    
  
    /* &:before {
      position: absolute;
      content: "";
      width: 1px;
      background-color: #58585B;
      height: 40px;
      top: 3px;
      left: 0;
    } */
}

.dashboard-navbar {
    position: sticky;
    top: 0;
    width: 100vw;
    background: linear-gradient(90deg,#0D274D .29%,#1E4471 100%);
    justify-content: left;
    align-items: center;
    padding: 10px;
    padding-right: 20px;
    height: 56px;
    z-index: 1000;
  }